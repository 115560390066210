<template>
  <div>
    <!-- SPナビゲーション開閉ボタン -->
    <button
      class="flex items-center px-5 text-[2rem] lg:hidden"
      aria-label="Toggle navigation"
      @click="isMenuOpen = !isMenuOpen"
    >
      <i
        class="fa fa-bars"
        aria-hidden="true"
      />
    </button>

    <!-- 開閉メニュー -->
    <Transition name="fade">
      <div
        v-show="isMenuOpen"
        class="te-collapse flex flex-col px-4 lg:hidden"
      >
        <a
          href="/course"
          class="co-display-11 block w-full border-b py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          コース紹介
        </a>
        <!-- 開閉メニュー -->
        <details class="collapse collapse-arrow overflow-visible">
          <summary class="co-display-11 collapse-title block w-full border-b pb-2 pl-0 pt-3 text-co-gray-700 hover:text-co-gray-700">
            はじめての方へ
          </summary>
          <div class="collapse-content flex flex-col px-3">
            <a
              href="/about"
              class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700"
            >テックアカデミーとは</a>
            <a
              href="/diagnoses/top"
              class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700"
            >オススメコース診断</a>
            <a
              href="/course/occupation"
              class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700"
            >Web制作の仕事を解説</a>
            <a
              href="/price"
              class="px-2 py-3 text-co-gray-700 hover:text-co-gray-700"
            >料金プラン</a>
            <a
              href="/counseling/htmlcss-schedule"
              class="bot_open px-2 py-3 text-co-gray-700 hover:text-co-gray-700"
            >無料相談</a>
          </div>
        </details>
        <a
          href="/blog"
          class="co-display-11 block w-full border-b py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          受講生の声
        </a>
        <a
          href="/sidejob"
          class="co-display-11 block w-full border-b py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          副業支援
        </a>
        <a
          href="/jobchange"
          class="co-display-11 block w-full border-b py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          転職支援
        </a>
        <a
          href="/biz/training"
          class="co-display-11 block w-full border-b py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          法人向け
          <i
            class="fa fa-angle-right ml-3"
            aria-hidden="true"
          />
        </a>
        <a
          href="/login"
          class="block w-full px-0 py-3 text-co-gray-700 hover:text-co-gray-700"
        >
          ログイン
        </a>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const isMenuOpen = ref(false);
</script>

<style scoped>
/* 開閉ナビゲーション */
.te-collapse {
  @apply absolute top-[70px] left-0 w-full h-[calc(100vh-70px)] border-b border-gray-300 overflow-scroll;
  background-color: rgba(255, 255, 255, 0.97);
}

/* 開閉メニュー */
.collapse[open] > :where(.collapse-content) {
  @apply pb-0;
}

/* フェードアニメーション */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
